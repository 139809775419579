import React, {useState} from 'react';
import {Alignment, Button, Card, H1, NonIdealState, Overlay} from "@blueprintjs/core";
import {Select} from "@blueprintjs/select";

import PredefinedFactory, {PREDEFINED_GRAPHS} from "../../predefined/PredefinedFactory";
import {listElementPredicate, listElementRenderer} from "./ListWithParameters/helpers";
import './ListWithParameters.scss';
import {getType} from "./ListWithParameters";

export default function PredefinedPopover({isOpen, onClose, updateGraphData}) {
  const [selectedGraph, setSelectedGraph] = useState(null);

  const graphSelected = (graph) => {
    setSelectedGraph(graph)
  };

  function getGraphType() {
    return getType(selectedGraph);
  }

  const getPredefinedInfo = () => {
    const type = getGraphType();

    if (!type) {
      return <NonIdealState icon="cross" title="No graph selected" description="Choose graph from list above to load it." />
    } else {
      return <NonIdealState icon="tick" title={type} description={PredefinedFactory.getPredefinedDescription(type)} />
    }
  };

  const loadGraph = () => {
    const type = getGraphType();

    if (type) {
      const elements = PredefinedFactory.getPredefinedElements(type);
      updateGraphData(elements);
      onClose();
    }
  };

  return <Overlay className="PredefinedPopover ListWithParameters" isOpen={isOpen} onClose={onClose}>
    <Card className="bp3-dark">
      <H1>Load predefined graph</H1>
      <Select
        className="select-element" popoverProps={{usePortal: false}}
        items={Object.entries(PREDEFINED_GRAPHS)} onItemSelect={graphSelected}
        itemRenderer={listElementRenderer(selectedGraph)}
        itemPredicate={listElementPredicate}
      >
        <Button large text={getGraphType() || "Choose generator type"}
          icon="graph" rightIcon="caret-down"
          alignText={Alignment.LEFT}
        />
      </Select>
      <div className="parameters">
        { getPredefinedInfo() }
      </div>
      <Button className="add-element-button" text="Load graph" large disabled={!selectedGraph} onClick={loadGraph} />
    </Card>
  </Overlay>
}