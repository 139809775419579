import {Centrality} from "../centrality";

export default class Harmonic extends Centrality {
  getName() {
    return "Harmonic";
  }

  calculateCentralityForNode(node) {
    let value = 0;

    node.outgoers('edge').forEach((edge) => {
      let weight = edge.data('weight');
      if (weight === undefined) {
        throw Error('Need edge weights for harmonic centrality');
      } else {
        if (weight > 0)
          value += 1 / parseInt(weight);
      }
    });

    if (!this.isDirected) {
      node.incomers('edge').forEach((edge) => {
        let weight = edge.data('weight');
        if (weight === undefined) {
          throw Error('Need edge weights for harmonic centrality');
        } else {
          if (weight > 0)
            value += 1 / parseInt(weight);
        }
      });
    }

    return value;
  }
}