import React, {useState} from 'react';
import {Alignment, Button, Card, H1, MenuItem, NonIdealState, Overlay} from "@blueprintjs/core";
import {Select} from "@blueprintjs/select";
import CentralityFactory, {CENTRALITY_TYPES} from "../../centralities/centralityFactory";

import './ListWithParameters.scss';

export default function CentralityPopover({isOpen, onClose, addCentrality}) {
  const [selectedCentrality, setSelectedCentrality] = useState(null);
  const [parameters, setParameters] = useState({});

  const centralitySelected = (centrality) => {
    setSelectedCentrality(centrality);
  };

  const getParametersForCentrality = (centrality) => {
    if (!centrality) {
      return <NonIdealState icon="cross" title="No centrality selected" description="Choose centrality from list above to set it's parameters and to calculate it for your graph." />
    } else {
      const parametersComponent = CentralityFactory.getCentralityParametersComponent(centrality[1], parameters, setParameters);
      if (!parametersComponent) {
        return <NonIdealState icon="tick" title="This centrality has no parameters"/>
      } else {
        return parametersComponent;
      }
    }
  };

  const centralitySelectRenderer = ([id, name], {handleClick}) => {
    return <MenuItem
      key={id}
      text={name}

      active={id === (selectedCentrality ? selectedCentrality[0] : null)}

      onClick={handleClick}
    />
  };

  const addSelectedCentrality = () => {
    if (selectedCentrality) {
      addCentrality(selectedCentrality[1], parameters);
      onClose();
    }
  };

  return <Overlay className="CentralityPopover ListWithParameters" isOpen={isOpen} onClose={onClose}>
    <Card className="bp3-dark">
      <H1>Adding centrality</H1>
      <Select
        className="select-element" popoverProps={{usePortal: false}}
        itemRenderer={centralitySelectRenderer}  items={Object.entries(CENTRALITY_TYPES)} onItemSelect={centralitySelected}
      >
        <Button large text={selectedCentrality ? selectedCentrality[1] : "Choose centrality"}
          icon="graph" rightIcon="caret-down"
          alignText={Alignment.LEFT}
        />
      </Select>
      <div className="parameters">
        { getParametersForCentrality(selectedCentrality) }
      </div>
      <Button className="add-element-button" text="Add centrality" large disabled={!selectedCentrality} onClick={addSelectedCentrality} />
    </Card>
  </Overlay>
}