import {Centrality} from "../centrality";
import {FormGroup, HTMLSelect} from "@blueprintjs/core";
import React from "react";

export const DIRECTIONS = {
  OUT: "out",
  IN: "in",
  BOTH: "both",
};

export default class Degree extends Centrality {
  constructor(cy, parameters) {
    super(cy, parameters);
    this.direction = parameters["direction"];
  }

  getName() {
    return `Degree (${this.direction})`;
  }

  calculateCentralityForNode(node) {
    let degreeInfo = this.cy.elements().degreeCentrality({
      directed: this.isDirected,
      weight: this.getEdgeWeight,
      alpha: 1,
      root: node
    });

    return this.isDirected ? this.getValueForDirected(degreeInfo) : degreeInfo['degree'];
  }

  getValueForDirected(degreeInfo) {
    switch(this.direction) {
      case DIRECTIONS.OUT:
        return degreeInfo["outdegree"];
      case DIRECTIONS.IN:
        return degreeInfo["indegree"];
      default: // both
        return degreeInfo["outdegree"] + degreeInfo["indegree"];
    }
  }

  static getParametersComponent(parameters, setParameters) {
    return <DegreeParameters parameters={parameters} setParameters={setParameters}/>
  }
}

function DegreeParameters({parameters, setParameters}) {
  function directionChanged(event) {
    const value = event.target.value;
    setDirection(value);
  }

  function setDirection(value) {
    setParameters({
      ...parameters,
      direction: value,
    })
  }

  if (parameters["direction"] === undefined) {
    setDirection(DIRECTIONS.OUT)
  }

  return <>
    <FormGroup
      label="Direction of nodes included"
      labelFor="direction"
      helperText="If graph is directed, what kind of edges will be used to calculate degree"
      inline
    >
      <HTMLSelect
        value={parameters["direction"] || DIRECTIONS.OUT}
        onChange={directionChanged}
      >
        <option value={DIRECTIONS.OUT}>Just outgoing</option>
        <option value={DIRECTIONS.IN}>Just incoming</option>
        <option value={DIRECTIONS.BOTH}>Both outgoing and incoming</option>
      </HTMLSelect>
    </FormGroup>
  </>
}