export class Centrality {
  constructor(cy, {isDirected}) {
    this.cy = cy;
    this.isDirected = isDirected;

    this.values = false;
  }

  setCy(cy) {
    this.cy = cy;
    this.resetValues();
  }

  getName() {
    return '';
  }

  getValues() {
    if (!this.values) {
      this.updateCentralitiesValues()
    }

    return this.values;
  }

  getValue(id) {
    if (!this.values) {
      this.updateCentralitiesValues();
    }

    return this.values[id]
  }

  hasValues() {
    return !!this.values;
  }

  setDirected(isDirected) {
    this.isDirected = isDirected;
  }

  resetValues() {
    this.values = false;
  }

  updateCentralitiesValues() {
    this.values = {};

    this.cy.nodes().forEach((node) => {
      const nodeId = node.data("id");
      this.values[nodeId] = this.calculateCentralityForNode(node);
    });
  }

  calculateCentralityForNode(node) {
    return 0;
  }

  getEdgeWeight(edge) {
    let weight = parseFloat(edge.data("weight"));
    if (isNaN(weight)) {
      weight = 1;
    }

    return weight;
  }

  static getParametersComponent(parameters, setParameters) {
    return ''
  }
}