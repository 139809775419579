import Parameters from "../components/subcomponents/Parameters";
import React from "react";

export default class Generator {
  static PARAMETER_TYPES = [];

  constructor(parameters) {
    this.parameters = parameters;
  }

  generateData() {
    throw Error("Should be overwritten in child class.");
  }

  static getParametersComponent(parameters, setParameters) {
    return <Parameters parameters={parameters} setParameters={setParameters} parameterTypes={this.PARAMETER_TYPES} />
  }
}