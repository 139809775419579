import React from 'react';
import {Card, TextArea} from "@blueprintjs/core";
import classNames from 'classnames';

import "./GraphTextEditor.scss";

export default function GraphTextEditor({text, onTextEditorChange, errorMessage}) {
  function onChange(event) {
    onTextEditorChange(event.target.value);
  }

  const showError = !!errorMessage;

  return (
    <div className={classNames("GraphTextEditor", {"invalid-input": showError})}>
      <TextArea name="graph-text" value={text} onChange={onChange} />
      <div className="text-editor-error">
        <Card>
          {errorMessage}
        </Card>
      </div>
    </div>
  );
}