import _ from 'lodash';
import Generator from "../Generator";
import Parameters from "../../components/subcomponents/Parameters";
import React from "react";

const PARAMETER_TYPES = [
    {
        id: "nodesNumber",
        type: "integer",
        defaultValue: 10,

        label: "Number of nodes",
        description: "Number of nodes in graph we are generating",
        min: 1,
        max: 100,
    },
    {
        id: "edgeProbability",
        type: "float",
        defaultValue: 0.25,

        label: "Edge probability",
        description: "Probability of adding an edge between two nodes",
        min: 0,
        max: 1,
    },
];

export default class ErdosRenyiGenerator extends Generator {
    static PARAMETER_TYPES = PARAMETER_TYPES;

    constructor(parameters) {
        super(parameters);
        this.nodesNumber = parameters["nodesNumber"];
        this.edgeProbability = parameters["edgeProbability"];
    }

    generateData() {
        let data = {
            nodes: [],
            edges: [],
        };

        // Adding edges
        for (let i = 1; i <= this.nodesNumber; i++) {
            data.nodes.push({
                data:
                    {
                        id: i,
                        label: i,
                        weight: null,
                    }
            })
        }

        for (let sourceId = 1; sourceId <= this.nodesNumber; sourceId++) {
            for (let targetId = 1; targetId <= this.nodesNumber; targetId++) {
                if ((sourceId !== targetId) && (Math.random() < this.edgeProbability)) {
                    data.edges.push({
                        data:{
                            id: sourceId + '-' + targetId,
                            source: sourceId,
                            target: targetId,
                            weight: _.random(1, 10),
                            label: null,
                        }
                    })
                }
            }
        }

        return data;
    }

    static getParametersComponent(parameters, setParameters) {
        return <Parameters parameters={parameters} setParameters={setParameters} parameterTypes={PARAMETER_TYPES} />
    }
}