import fileDialog from 'file-dialog';
import importTGF from "./importer/tgf";
import importGML from "./importer/gml";
import importPajek from "./importer/pajek";

export default class ImportFactory {
  static getElementsFromFile(type, setGraphElements) {
    let importFunction = ImportFactory.getImportFunction(type);
    ImportFactory.loadFile(importFunction, setGraphElements);
  }

  static getImportFunction(type) {
    switch (type) {
      case IMPORT_TYPES.TGF:
        return importTGF;
      case IMPORT_TYPES.GML:
        return importGML;
      case IMPORT_TYPES.PAJEK:
        return importPajek;
      default:
        throw Error("Unknown file type to import");
    }
  }

  static loadFile(importFunction, setGraphElements) {
    fileDialog().then(files => {
      if (files.length > 0) {
        const file = files[0];
        const reader = new FileReader();

        reader.onload = (reader => {
          const content = reader.target.result;
          const elements = importFunction(content);
          setGraphElements(elements);
        });
        reader.readAsText(file);
      }
    })
  }
}

export const IMPORT_TYPES = {
  TGF: "TGF",
  PAJEK: "Pajek",
  GML: "GML",
};