import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cola from 'cytoscape-cola';
import edgehandles from "cytoscape-edgehandles";
import _ from "lodash";

import CYTOSCAPE_CONFIG from "../configuration/cytoscape"
import EDGE_HANDLES_CONFIG from "../configuration/edgehandles";
import cytoscape from "cytoscape";

cytoscape.use(cola);
cytoscape.use(edgehandles);

export default class Cytoscape extends Component {
    cytoscapeConfig = _.cloneDeep(CYTOSCAPE_CONFIG);
    tappedBefore;
    tappedTimeout;
    
    static propTypes = {
        cy: PropTypes.object,

        displayOptions: PropTypes.object,
        elements: PropTypes.oneOfType([
          PropTypes.array,
          PropTypes.object,
        ]),
        isDirected: PropTypes.bool,

        onNodeClick: PropTypes.func,
        onEdgeClick: PropTypes.func,
        onBackgroundClick: PropTypes.func,
        
        onDoubleClick: PropTypes.func,

        onGraphChange: PropTypes.func,
    };
    
    constructor(props) {
        super(props);
        this.state = {
            cy: {},
            
            selectedElement: undefined,
        };
        
        this.cyRef = React.createRef();
    }

    componentDidMount() {
        const {elements, displayOptions, isDirected, onNodeClick, onEdgeClick, onBackgroundClick, onDoubleClick} = this.props;
        let {cy} = this.props;

        // FIXME: Workaround on bug, that disables interactivity when you mount cy
        // if (!cy) {
        this.cytoscapeConfig.container = this.cyRef.current;
        cy = cytoscape(this.cytoscapeConfig);
        this.props.updateCy(cy);
        // } else {
        //     cy.mount(this.cyRef.current);
        // }

        cy.scratch("_displayOptions", displayOptions);
        cy.scratch("_isDirected", isDirected);

        cy.json({elements});

        cy.on('tap', (evt) => {
            let target = evt.target;
            
            if (target === cy)
                onBackgroundClick();
            else if (target.isEdge())
                onEdgeClick(target);
            else if (target.isNode())
                onNodeClick(target);
        });
        this.edgeHandles = cy.edgehandles(EDGE_HANDLES_CONFIG);
        cy.on('mouseout', 'node', (event) => {
            // if (event.target.hasClass('eh-handle'))
            //     this.edgeHandles.show();
            if (!event.target.hasClass('eh-handle') && !this.hideEdgeHandleTimeout) {
                this.hideEdgeHandleTimeout = setTimeout(this.hideEdgeHandles, 150);
            }
        });
        cy.on('mousemove', 'node', (event) => {
            // if (event.target.hasClass('eh-handle'))
            //     this.edgeHandles.show();
            clearTimeout(this.hideEdgeHandleTimeout);
            this.hideEdgeHandleTimeout = undefined;
        });
        cy.on('ehcomplete', (event, sourceNode, targetNode, addedEles) => {
            this.props.onGraphChange();
        });
        cy.on('tap', (event) => {
            this.tappedNow = event.target;
            
            if (this.tappedTimeout && this.tappedBefore) {
                clearTimeout(this.tappedTimeout);
            }
            
            if (this.tappedBefore === this.tappedNow) {
                this.tappedNow.trigger('doubleTap', event);
                this.tappedBefore = null;
            } else {
                this.tappedTimeout = setTimeout(() => this.tappedBefore = null, 300);
                this.tappedBefore = this.tappedNow;
            }
        });
        cy.on('doubleTap', (event, originalEvent) => {
            onDoubleClick(originalEvent);
        });

        cy.layout({
            name: 'cola',
        }).run();
    }
    
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.isDirected !== this.props.isDirected) {
            this.edgesDirectionChanged();
        }
        
        if (!_.isEqual(this.props.displayOptions, prevProps.displayOptions)) {
            this.displayOptionsChanged();
        }

        if (prevProps.graphId !== this.props.graphId) {
            this.redrawGraph();
        }
    }
    
    edgesDirectionChanged = () => {
        const {cy, isDirected} = this.props;

        cy.scratch("_isDirected", isDirected);
        this.refreshCytoscapeConfig();
    };
    
    displayOptionsChanged = () => {
        const {cy, displayOptions} = this.props;
        
        cy.scratch("_displayOptions", displayOptions);
        this.refreshCytoscapeConfig();
    };
    
    render() {
        return (
            <div className="Cytoscape" ref={this.cyRef} />
        )
    }
    
    hideEdgeHandles = () => {
        this.edgeHandles.hide();
        this.hideEdgeHandleTimeout = undefined;
    };
    
    refreshShownCentrality = () => {
        const {cy} = this.props;

        let centralityType = this.state.displayOptions.centrality;
        let centralityData = this.state.centralities.data[centralityType];
        
        if (centralityType && centralityData) {
            cy.nodes().forEach((node) => {
                let nodeId = node.data('id');
                node.data('centrality', centralityData[nodeId]);
            })
        } else {
            cy.nodes().forEach((node) => {
                node.removeData('centrality');
            })
        }
    };
    
    refreshCytoscapeConfig = () => {
        const {cy, elements} = this.props;

        if (elements.nodes) {
            elements.nodes.forEach((node) => {
                delete node.position;
            })
        }
        this.cytoscapeConfig.elements = elements;
        cy.json(this.cytoscapeConfig);
    };
    
    redrawGraph = () => {
        const {cy} = this.props;

        cy.layout({
            name: 'cola',
        }).run();
    };
    
    getTopLeftPosition = () => {
        const {cy} = this.props;

        let minX = Infinity, minY = Infinity;
        cy.nodes().forEach((node) => {
            const [x, y] = [node.position().x, node.position().y];
            minX = Math.min(x, minX);
            minY = Math.min(y, minY);
        });
        
        return [minX, minY];
    };
    
    getEdgeWeight = (edge) => {
        return edge.data('weight');
    };
}

