export default class TikzConverter {
    static fromCytoscape(elements, directed, displayOptions) {
        let stringList = [
          '\\begin{tikzpicture}[x=10cm,y=10cm]',
          '  \\tikzset{default node/.style={circle,draw,minimum size=0.5},}\n'
        ];
        if (elements.nodes) {
            let maxX = -Infinity, maxY = -Infinity, minX = Infinity, minY = Infinity;
            for (let node of elements.nodes) {
                const [x, y] = [node.position.x, node.position.y];
                maxX = Math.max(x, maxX);
                minX = Math.min(x, minX);
                maxY = Math.max(y, maxY);
                minY = Math.min(y, minY);
            }
            
            let scale = 1 / (maxX - minX);
            
            for (const node of elements.nodes) {
                const [x, y] = [(node.position.x - minX) * scale, (maxY - (node.position.y - minY)) * scale];
                
                const data = node.data;
                const id = data.id;

                const description = TikzConverter.descriptionForNodeData(data, displayOptions);
                let nodeString = `  \\node[default node] (${id}) at (${x}, ${y}) {$${description}$};`;
                
                stringList.push(nodeString)
            }
            
            stringList.push('\n');
        }
        
        if (elements.edges) {
            stringList.push(`  \\path[${directed ? '->,' : ''}draw,thick]`);
            for (const edge of elements.edges) {
                const data = edge.data;

                const description = TikzConverter.descriptionForEdgeData(data, displayOptions);
                
                let edgeString = `  (${data.source}) edge ${description ? `node  [pos=0.5, sloped, above] {${description}}` : ''} (${data.target})`;

                stringList.push(edgeString)
            }
            stringList.push('  ;\n');
        }
        stringList.push('\\end{tikzpicture}');
        
        return stringList.join("\n");
    }

    static descriptionForNodeData(data, displayOptions) {
        let descriptionParts = [];

        const id = data.id;
        const label = data.label;
        const weight = data.weight;
        const centrality = data.centrality;

        if (isSet(label) && displayOptions.node_label) {
            descriptionParts.push(label)
        }

        if (isSet(id) && displayOptions.node_id) {
            if (descriptionParts.length > 0) {
                descriptionParts.push(`[${id}]`);
            } else {
                descriptionParts.push(id);
            }
        }

        if (isSet(weight) && displayOptions.node_weight) {
            if (descriptionParts.length > 0) {
                descriptionParts.push(`(${weight})`);
            } else {
                descriptionParts.push(weight);
            }
        }

        if (isSet(centrality) && displayOptions.centrality) {
            if (descriptionParts.length > 1) {
                descriptionParts.push(`{${centrality}}`);
            } else if (descriptionParts.length > 0) {
                descriptionParts.push(`(${centrality})`);
            } else {
                descriptionParts.push(centrality);
            }
        }

        return descriptionParts.join(" ");
    }

    static descriptionForEdgeData(data, displayOptions) {
        let descriptionParts = [];

        const label = data.label;
        const weight = data.weight;

        if (isSet(label) && displayOptions.edge_label) {
            descriptionParts.push(label)
        }

        if (isSet(weight) && displayOptions.edge_weight) {
            if (descriptionParts.length > 0) {
                descriptionParts.push(`(${weight})`);
            } else {
                descriptionParts.push(weight);
            }
        }

        return descriptionParts.join(" ");
    }
}

function isSet(el) {
    return el !== undefined && el !== null
}