const RE_NODES_HEADER = /^\*([Vv]ertices)\s(\d+)$/;
const RE_EDGES_HEADER = /^\*(([Aa]rcs)|([Ee]dges))$/;
const RE_EDGES_LIST_HEADER = /^\*[Ee]dges[Ll]ist$/;
const RE_NODE_INFO = /^(\d+)\s"(.+)"$/;
const RE_EDGE_WEIGHT = /^(\d+)\s(\d+)\s(\d+)$/;
const RE_EDGE_NO_WEIGHT = /^(\d+)\s(\d+)$/;
const RE_EDGE_LIST = /^(\d+)([\s\d+]+)$/;

const M_NODES_HEADER = 0;
const M_NODES_DATA = 1;
const M_EDGES_HEADER = 2;
const M_EDGES_DATA = 3;
const M_EDGES_LIST_DATA = 4;

const TYPE_EDGES_INFO = 0;
const TYPE_EDGES_LIST_INFO = 1;

export default class PajekConverter {
    static toCytoscape(pajekString) {
        let cytoscapeList = [];
        let lines = pajekString.split('\n');

        let currentMode = M_NODES_HEADER;
        let nodesCount;

        for (let line of lines) {
            line = PajekConverter.clearWhitespace(line);
            if (line.length > 0) {
                let processingLine = true;
                // noinspection FallThroughInSwitchStatementJS
                while (processingLine) switch (currentMode) {
                    case M_NODES_HEADER:
                        nodesCount = PajekConverter.readNodesCount(line);
                        if (nodesCount !== undefined) {
                            currentMode = M_NODES_DATA;
                            processingLine = false;
                        } else {
                            throw Error("Incorrect nodes header");
                        }
                        break;
                    case M_NODES_DATA:
                        let nodesData = PajekConverter.readNodeInfo(line);
                        if (nodesData !== undefined) {
                            cytoscapeList.push(this.getCytoscapeNode(nodesData.id, nodesData.label, nodesData.weight))
                            break;
                        } else {
                            if (cytoscapeList.length === 0) {
                                for (let i = 1; i <= nodesCount; i++) {
                                    cytoscapeList.push(this.getCytoscapeNode(i))
                                }
                            } else if (cytoscapeList.length !== nodesCount) {
                                throw Error("Not enough nodes data");
                            }

                            currentMode = M_EDGES_HEADER;
                        }
                        break;
                    case M_EDGES_HEADER:
                        let edgesDataType = PajekConverter.readEdgesDataType(line);
                        processingLine = false;

                        if (edgesDataType === TYPE_EDGES_INFO) {
                            currentMode = M_EDGES_DATA;
                        } else if (edgesDataType === TYPE_EDGES_LIST_INFO) {
                            currentMode = M_EDGES_LIST_DATA;
                        } else {
                            throw Error("Wrong edges info header.")
                        }
                        break;
                    case M_EDGES_DATA:
                        let edgeData = PajekConverter.readEdgeInfo(line);
                        processingLine = false;

                        if (edgeData !== undefined) {
                            cytoscapeList.push(PajekConverter.getCytoscapeEdge(edgeData.source, edgeData.target, edgeData.weight))
                        } else {
                            throw Error("Incorrect edges data line")
                        }
                        break;
                    case M_EDGES_LIST_DATA:
                        let edgeListData = PajekConverter.readEdgeListInfo(line);
                        processingLine = false;

                        if (edgeListData !== undefined) {
                            let sourceId = edgeListData.source;
                            for (let targetId of edgeListData.targets) {
                                cytoscapeList.push(PajekConverter.getCytoscapeEdge(sourceId, targetId));
                            }
                        } else {
                            throw Error("Incorrect edges data line")
                        }
                        break;
                    default:
                        throw Error("Incorrect type")

                }
            }
        }

        return cytoscapeList;
    }

    static readNodesCount(line) {
        let nodesCount;
        let headerData = RE_NODES_HEADER.exec(line);

        if (headerData) {
            nodesCount = headerData[2];
        }

        return nodesCount;
    }

    static readNodeInfo(line) {
        let nodeInfo;
        let nodeData = RE_NODE_INFO.exec(line);

        if (nodeData) {
            nodeInfo = {
                id: nodeData[1],
                label: nodeData[2],
            }
        }

        return nodeInfo;
    }

    static readEdgesDataType(line) {
        let edgeDataType;

        if (RE_EDGES_HEADER.exec(line)) {
            edgeDataType = TYPE_EDGES_INFO;
        } else if (RE_EDGES_LIST_HEADER.exec(line)) {
            edgeDataType = TYPE_EDGES_LIST_INFO;
        }

        return edgeDataType;
    }

    static readEdgeInfo(line) {
        let edgeInfo;
        let edgeData = RE_EDGE_WEIGHT.exec(line);

        if (edgeData) {
            edgeInfo = {
                source: edgeData[1],
                target: edgeData[2],
                weight: edgeData[3],
            }
        } else {
            edgeData = RE_EDGE_NO_WEIGHT.exec(line);

            if (edgeData) {
                edgeInfo = {
                    source: edgeData[1],
                    target: edgeData[2],
                }
            }
        }

        return edgeInfo;
    }

    static readEdgeListInfo(line) {
        let edgeInfo;
        let edgeData = RE_EDGE_LIST.exec(line);

        if (edgeData) {
            edgeInfo = {
                source: edgeData[1],
                targets: edgeData[2].trim().split(' '),
            }
        }

        return edgeInfo;
    }



    static getCytoscapeNode(id, label=null, weight=null) {
        return {
            data: {
                id,
                label,
                weight,
            }
        }
    }

    static getCytoscapeEdge(source, target, weight=null, label=null) {
        return {
            data: {
                id: `${source}-${target}`,
                source,
                target,
                weight,
                label,
            }
        }
    }

    static clearWhitespace(string) {
        let newString = string.trim();
        newString = newString.replace(/\s+/g, " ");
        return newString;
    }
}