import React from 'react';
import {
    Alignment,
    Button,
    ButtonGroup,
    Menu,
    MenuItem,
    Navbar,
    Popover,
    Position,
    Switch,
} from "@blueprintjs/core/lib/esm/index";

import "./GraphModificationsToolbar.scss";
import {EXPORT_TYPES} from "../../../exporters/exportFactory";
import {IMPORT_TYPES} from "../../../importers/ImportFactory";

export default function GraphModificationsToolbar({isDirected, toggleDirected, generateGraph, loadPredefined, importAction, exportAction}) {
    return (
        <Navbar className="GraphModificationsToolbar">
            <Navbar.Group align={Alignment.RIGHT}>
                <Switch checked={isDirected} innerLabel="undirected" innerLabelChecked="directed"
                    onChange={toggleDirected}/>
                <Navbar.Divider style={{margin: "0 5px"}}/>
                <ButtonGroup>
                    <Popover content={<ImportMenu generateGraph={generateGraph} loadPredefined={loadPredefined} importAction={importAction} />} position={Position.BOTTOM}>
                        <Button minimal text="Import" rightIcon="caret-down"/>
                    </Popover>
                    <Popover content={<ExportMenu exportAction={exportAction}/>} position={Position.BOTTOM}>
                        <Button minimal text="Export" rightIcon="caret-down"/>
                    </Popover>
                </ButtonGroup>
            </Navbar.Group>
        </Navbar>
    )
}

function ExportMenu({exportAction}) {
    return <Menu>
        {Object.entries(EXPORT_TYPES).map(([id, name], index) => (
            <MenuItem key={index} text={name} onClick={exportAction(name)} />
        ))}
    </Menu>
}

function ImportMenu({generateGraph, loadPredefined, importAction}) {
    return (
        <Menu>
            <MenuItem text="From file" popoverProps={{ openOnTargetFocus: false }}>
                {Object.entries(IMPORT_TYPES).map(([id, name]) => (
                    <MenuItem key={id} text={name} onClick={importAction(name)} />
                ))}
            </MenuItem>
            <MenuItem text="Generate data" onClick={generateGraph}/>
            <MenuItem text="Load predefined" onClick={loadPredefined}/>
        </Menu>
    )
}