import {MenuItem} from "@blueprintjs/core";
import React from "react";

export function listElementPredicate(query, item) {
  return item[1].toLowerCase().includes(query.toLowerCase());
}

export const listElementRenderer = (selectedElement) => ([id, name], {handleClick, modifiers}) => {
  return <MenuItem
    key={id}
    text={name}
    icon={selectedElement && selectedElement[0] === id ? "tick" : "blank"}

    active={modifiers.active}

    onClick={handleClick}
  />
};