import React from 'react';

import './ViewOptionsDropdown.scss';
import {Menu} from "@blueprintjs/core";

const NODE_OPTIONS = {
    "node_id": "ID",
    "node_label": "Label",
    "node_weight": "Weight",
    "centrality": "Centrality",
};

const EDGE_OPTIONS = {
    "edge_label": "Label",
    "edge_weight": "Weight",
};

export default function ViewOptionsDropdown({displayOptions, toggleDisplayOption}) {
    function getIconName(type) {
        if (displayOptions[type]) {
            return "tick-circle";
        } else {
            return "circle";
        }
    }
    
    function toggleAction(type) {
        return () => {
            toggleDisplayOption(type);
        }
    }
    
    return (
        <Menu>
            <Menu.Divider title="Nodes" />
                {
                    Object.entries(NODE_OPTIONS).map(([id, name], index) => (
                        <Menu.Item key={index} text={name}
                            icon={getIconName(id)} onClick={toggleAction(id)}
                        />
                    ))
                }
            <Menu.Divider title="Edges" />
                {
                    Object.entries(EDGE_OPTIONS).map(([id, name], index) => (
                        <Menu.Item key={index} text={name}
                            icon={getIconName(id)} onClick={toggleAction(id)}
                        />
                    ))
                }
        </Menu>
    );
}