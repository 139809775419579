const NODE_WEIGHT_RE = /^([a-zA-Z0-9\-_]+)\s(\d+)\s(.+)$/;
const NODE_NO_WEIGHT_RE = /^([a-zA-Z0-9\-_]+)\s?(.*)$/;
const EDGE_WEIGHT_RE = /^([a-zA-Z0-9\-_]+)\s([a-zA-Z0-9\-_]+)\s(\d+)\s?(.*)$/;
const EDGE_NO_WEIGHT_RE = /^([a-zA-Z0-9\-_]+)\s([a-zA-Z0-9\-_]+)\s?(.*)$/;

let EDGE_ID = 0;

export default class TGFConverter {
    static fromCytoscape(elements) {
        let nodes = [];
        
        if (Array.isArray(elements)) {
            return TGFConverter.fromCytoscapeArray(elements)
        } else {
            if (elements.nodes) {
                for (let node of elements.nodes) {
                    nodes.push(TGFConverter.processCytoscapeNode(node))
                }
            }
    
            let edges = [];
            if (elements.edges) {
                for (let edge of elements.edges) {
                    edges.push(TGFConverter.processCytoscapeEdge(edge))
                }
            }
    
            return nodes.join("\n") + "\n#\n" + edges.join("\n");
        }
    }
    
    static fromCytoscapeArray(elements) {
        let nodes = [];
        let edges = [];
        
        for (let element of elements) {
            if ('source' in element.data) {
                edges.push(TGFConverter.processCytoscapeEdge(element));
            } else {
                nodes.push(TGFConverter.processCytoscapeNode(element));
            }
        }
    
        return nodes.join("\n") + "\n#\n" + edges.join("\n");
    }
    
    static processCytoscapeNode(node) {
        let data = node.data;
        let nodeString = data.id;

        for (let fieldName of ['weight', 'label']) {
            if (data[fieldName])
                nodeString += ` ${data[fieldName]}`
        }
        
        return nodeString;
    }
    
    static processCytoscapeEdge(edge) {
        let data = edge.data;

        let edgeString = `${data.source} ${data.target}`;
        for (let fieldName of ['weight', 'label']) {
            if (data[fieldName])
                edgeString += ` ${data[fieldName]}`
        }
        
        return edgeString;
    }

    static toCytoscape(tgfString) {
        let cytoscapeList = [];
        let ids = [];
        let lines = tgfString.split('\n');
        EDGE_ID = 0;

        let readingNodes = true;

        for (let line of lines) {
            let clearedLine = TGFConverter.clearWhitespace(line);
            if (clearedLine.length > 0) {
                if (readingNodes) {
                    let node = TGFConverter.readNodeFromLine(clearedLine);
                    if (node) {
                        cytoscapeList.push(node);
                        ids.push(node.data.id);
                    } else {
                        let separatorFound = TGFConverter.checkForSeparator(clearedLine);
                        if (separatorFound) {
                            readingNodes = false;
                        } else {
                            throw Error("Invalid line after nodes list - # expected");
                        }
                    }
                } else {
                    let edge = TGFConverter.readEdgeFromLine(clearedLine);
                    if (edge) {
                        if (ids.includes(edge.data.source) && ids.includes(edge.data.target)) {
                            cytoscapeList.push(edge);
                        } else {
                            throw Error(`Found edge with non-existent source or target (${edge.data.source} to ${edge.data.target})`)
                        }
                    } else {
                        throw Error("Correct edge definition not found");
                    }
                }
            }
        }

        return cytoscapeList;
    }

    static readNodeFromLine(line) {
        let node = false;
        let weight = true;
        let values = NODE_WEIGHT_RE.exec(line);
        if (!values) {
            values = NODE_NO_WEIGHT_RE.exec(line);
            weight = false
        }

        if (values) {
            if (weight) {
                node = {
                    data: {
                        id: values[1],
                        weight: values[2],
                        label: values[3],
                    }
                }
            } else {
                node = {
                    data: {
                        id: values[1],
                        weight: null,
                        label: values[2],
                    }
                }
            }
        }

        return node;
    }

    static readEdgeFromLine(line) {
        let edge = false;
        let weight = true;
        let values = EDGE_WEIGHT_RE.exec(line);
        if (!values) {
            values = EDGE_NO_WEIGHT_RE.exec(line);
            weight = false;
        }

        if (values) {
            if (weight) {
                edge = {
                    data: {
                        id: `${values[1]}-${values[2]}-${EDGE_ID}`,
                        source: values[1],
                        target: values[2],
                        weight: values[3],
                        label: values[4] ? values[4] : null,
                    }
                }
            } else {
                edge = {
                    data: {
                        id: `${values[1]}-${values[2]}-${EDGE_ID}`,
                        source: values[1],
                        target: values[2],
                        weight: null,
                        label: values[3] ? values[3] : null,
                    }
                }
            }
        }

        EDGE_ID++;

        return edge;
    }

    static checkForSeparator(line) {
        return line.length === 1 && line[0] === '#';
    }

    static clearWhitespace(string) {
        let newString = string.trim();
        newString = newString.replace(/\s+/g, " ");
        return newString;
    }
}