import React, {Component} from 'react';
import GraphVisualization from "../components/GraphVisualization";
import TGFConverter from "../converters/TGFConverter";
import _ from 'lodash';

import cytoscape from "cytoscape";
import jquery from 'jquery';
import graphml from 'cytoscape-graphml';

import CYTOSCAPE_CONFIG from "../configuration/cytoscape"

import GraphModificationsToolbar from "../components/subcomponents/GraphEditor/GraphModificationsToolbar";
import exportFactory from "../exporters/exportFactory";
import GraphTable from "../components/GraphTable";

import "./App.scss";
import GraphTextEditor from "../components/GraphTextEditor";
import CentralityFactory, {CENTRALITY_TYPES} from "../centralities/centralityFactory";
import CentralityPopover from "../components/subcomponents/CentralityPopover";
import GenerationPopover from "../components/subcomponents/GenerationPopover";
import PredefinedPopover from "../components/subcomponents/PredefinedPopover";
import ImportFactory from "../importers/ImportFactory";
import HelpPopover from "../components/subcomponents/HelpPopover";
import {DIRECTIONS} from "../centralities/centrality/degree";


graphml(cytoscape, jquery);

const DEFAULT_DISPLAY_OPTIONS = {
  node_id: true,
  node_label: true,
  node_weight: true,
  edge_label: true,
  edge_weight: true,
  centrality: false,
};

const GRAPHS = {
  a:
    `1 A
2 B
3 C
4 D
#
1 2 2
2 3 4
3 1 5
4 2 6`,
  b:
    `1 A
2 B
3 C
4 D
#
2 3 4
4 1 3
2 4 6
1 2 7`,
};

const DEFAULT_GRAPH_TXT = GRAPHS.a;

export default class App extends Component {
  cy;
  graphTable = React.createRef();
  majorChange = false;

  constructor(props) {
    super(props);

    let text = DEFAULT_GRAPH_TXT;
    let elements = TGFConverter.toCytoscape(text);

    // FIXME: Currently updated, because of bug
    this.cy = cytoscape(CYTOSCAPE_CONFIG);

    this.state = {
      cy: this.cy,
      graphId: 0,

      displayOptions: DEFAULT_DISPLAY_OPTIONS,

      text,
      elements,
      nodes: [],
      changeLayout: true,
      isDirected: true,

      exportComponent: '',
      textEditorError: '',

      autoRefreshCentralities: true,

      popovers: {
        centrality: false,
        generation: false,
        predefined: false,
      },

      centralities: [
        CentralityFactory.getCentralityObject(CENTRALITY_TYPES.BETWEENNESS, this.cy, {isDirected: true}),
        CentralityFactory.getCentralityObject(CENTRALITY_TYPES.CLOSENESS, this.cy, {isDirected: true}),
        CentralityFactory.getCentralityObject(CENTRALITY_TYPES.DEGREE, this.cy, {isDirected: true, direction: DIRECTIONS.OUT}),
      ],
      highlightedCentralityIndex: undefined,
    };
  }

  // FIXME: Workaround to bug, that disables interactivity to headless graphs being mounted
  updateCy = (cy) => {
    this.cy = cy;

    for (let centrality of this.state.centralities) {
      centrality.setCy(this.cy);
    }

    this.setState({
      cy
    }, () => {
      this.updateElements();
    });
  };

  render() {
    const {
      isDirected, exportComponent, nodes, text, textEditorError, popovers, autoRefreshCentralities, graphId,
      centralities, highlightedCentralityIndex,
      displayOptions,
    } = this.state;

    return <div id="main-container" className="bp3-dark">
      <div id="graph-col">
        <GraphVisualization
          isDirected={isDirected}
          elements={this.state.elements} onGraphChange={this.graphElementsChanged}
          changeLayout={this.state.changeLayout}
          graphId={graphId}
          toggleHelp={this.togglePopoverAction('help')}

          displayOptions={displayOptions} toggleDisplayOptions={this.toggleOptionAction("displayOptions")}

          exportComponent={exportComponent}

          cy={this.cy}
          updateCy={this.updateCy}
          addElement={this.addElement}
        />
        <GraphTable ref={this.graphTable}
          centralities={centralities} nodes={nodes}
          addCentrality={this.togglePopoverAction('centrality')}
          removeCentralityByIndex={this.removeCentralityByIndex}
          autoRefresh={autoRefreshCentralities} toggleAutoRefresh={this.toggleAutoRefresh} manualRefresh={this.refreshCentralities}
          highlightedCentralityIndex={highlightedCentralityIndex}
          setHighlightedCentralityIndex={this.setHighlightedCentralityIndex}
        />
      </div>
      <div id="editor-col">
        <GraphModificationsToolbar
          isDirected={isDirected} toggleDirected={this.toggleDirected}
          generateGraph={this.togglePopoverAction('generation')}
          loadPredefined={this.togglePopoverAction('predefined')}
          importAction={this.importAction}
          exportAction={this.exportAction}
        />
        <GraphTextEditor
          text={text} onTextEditorChange={this.textEditorChanged}
          errorMessage={textEditorError}
        />
      </div>
      <CentralityPopover isOpen={popovers.centrality} onClose={this.togglePopoverAction('centrality')} addCentrality={this.addCentrality} />
      <GenerationPopover isOpen={popovers.generation} onClose={this.togglePopoverAction('generation')} updateGraphData={this.updateGraphData}/>
      <PredefinedPopover isOpen={popovers.predefined} onClose={this.togglePopoverAction('predefined')} updateGraphData={this.updateGraphData}/>
      <HelpPopover isOpen={popovers.help} onClose={this.togglePopoverAction('help')} />
    </div>
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!_.isEqual(prevState.elements, this.state.elements)) {
      this.updateElements();
    }
  }

  updateElements = () => {
    const {elements} = this.state;
    this.cy.json({elements});

    if (this.cy) {
      this.setState(state => ({
        nodes: this.cy.nodes().toArray(),
        graphId: this.majorChange ? state.graphId + 1 : state.graph,
      }));
    }
  };

  updateGraphData = (elements) => {
    this.majorChange = true;
    this.setState({
      elements,
    }, () => {
      this.updateTextEditor();
      this.checkCentralitiesRefresh();
    });
  };

  componentWillUnmount() {
    if (this.cy) {
      this.cy.destroy();
    }
  }

  togglePopoverAction = (popoverType) => () => {
    this.setState(state => {
      return {
        popovers: {
          ...state.popovers,
          [popoverType]: !state.popovers[popoverType],
        },
      }
    })
  };

  toggleAutoRefresh = () => {
    this.setState(state => {
      return {
        autoRefreshCentralities: !state.autoRefreshCentralities,
      }
    })
  };

  toggleOptionAction = (option, actionSubKey) => (parameterSubKey) => {
    let subKey = actionSubKey;
    if (!subKey) {
      subKey = parameterSubKey;
    }

    this.setState(state => {
      return {
        [option]: {
          ...state[option],
          [subKey]: !state[option][subKey],
        }
      }
    })
  };

  addElement = (element) => {
    this.majorChange = false;

    this.setState(state => {
      const newElements = [...state.elements, element];
      const text = TGFConverter.fromCytoscape(newElements);

      return {
        elements: [...state.elements, element],
        text
      }
    });
  };

  addCentrality = (type, parameters) => {
    this.setState(state => {
      const centralities = [
        ...state.centralities,
        CentralityFactory.getCentralityObject(
          type,
          this.cy,
          {
            ...App.getDefaultCentralityParameters(state),
            ...parameters
          })
      ];

      return {
        centralities,
      }
    })
  };

  removeCentralityByIndex = (index) => {
    let shouldUpdatedHighlightedCentrality = false;

    this.setState(state => {
      let {highlightedCentralityIndex} = state;
      if (highlightedCentralityIndex === index) {
        highlightedCentralityIndex = undefined;
        shouldUpdatedHighlightedCentrality = true;
      } else if (highlightedCentralityIndex > index) {
        highlightedCentralityIndex--
      }

      let centralities = [
        ...state.centralities
      ];
      centralities.splice(index, 1);

      return {
        centralities,
        highlightedCentralityIndex,
      }
    }, () => {
      if (shouldUpdatedHighlightedCentrality) {
        this.updateHighlightedCentralityData()
      }
    })
  };

  setHighlightedCentralityIndex = (index) => {
    this.setState({
      highlightedCentralityIndex: index,
    }, this.updateHighlightedCentralityData)
  };

  updateHighlightedCentralityData = () => {
    const {centralities, highlightedCentralityIndex} = this.state;
    const highlightedCentrality = centralities[highlightedCentralityIndex];

    if (highlightedCentralityIndex === undefined) {
      this.cy.nodes().removeData('centrality')
    } else {
      const [min, max] = this.minMaxValuesForCentrality(highlightedCentrality);

      this.cy.nodes().forEach((node) => {
        const id = node.id();
        const value = highlightedCentrality.getValue(id);
        let centralityPercentage
        if (max === min || max-min === 0) {
          centralityPercentage = 0;
        } else {
          centralityPercentage = (value - min) / (max - min);
          if (centralityPercentage > 1) {
            centralityPercentage = 1;
          }  else if (centralityPercentage < 0) {
            centralityPercentage = 0;
          }
        }

        node.data('centrality', highlightedCentrality.getValue(id));
        node.data('centralityPercentage', centralityPercentage);
      })
    }
  };

  static getDefaultCentralityParameters(state) {
    return {
      isDirected: state.isDirected,
    }
  }

  toggleDirected = () => {
    this.setState(state => ({
      isDirected: !state.isDirected,
    }), this.checkCentralitiesRefresh);
  };

  importAction = (type) => () => {
    ImportFactory.getElementsFromFile(type, this.updateGraphData);
  };

  exportAction = (type) => () => {
    const {cy, isDirected, displayOptions} = this.state;
    const exporter = exportFactory.getExporter(type, {
      cy,
      elementsForExport: this.getCyElements(),
      isDirected,
      displayOptions,
    });
    exporter.export();
    this.setState({
      exportComponent: exporter.getComponent(),
    });
  };

  getCyElementsArray = () => {
    return this.getCyElements(true);
  };

  getCyElements = (asArray = false) => {
    const {cy} = this.state;
    let elements;
    if (asArray) {
      elements = []
    } else {
      elements = {
        nodes: [],
        edges: [],
      };
    }

    for (let node of cy.json().elements.nodes) {
      if (!node.classes.includes('eh-ghost') && !node.classes.includes('eh-handle')) {
        if (asArray) {
          elements.push(node)
        } else {
          elements.nodes.push(node)
        }
      }
    }

    for (let edge of cy.json().elements.edges) {
      if (!edge.classes.includes('eh-ghost')) {
        if (asArray) {
          elements.push(edge)
        } else {
          elements.edges.push(edge)
        }
      }

    }

    return elements;
  };

  changePredefinedGraph = (event) => {
    this.setState({
      predefinedGraph: event.target.value
    });
  };

  loadPredefinedGraph = () => {
    if (this.state.predefinedGraph)
      this.graphTextUpdate(GRAPHS[this.state.predefinedGraph]);
  };

  textEditorChanged = (text) => {
    this.majorChange = false;

    this.setState(state => {
      let elements = state.elements;
      let textEditorError = "";

      try {
        elements = TGFConverter.toCytoscape(text);
      } catch (e) {
        textEditorError = e.message;
      }

      return {
        text,
        textEditorError,
        elements,
      }
    }, this.checkCentralitiesRefresh)
  };

  updateTextEditor = () => {
    this.setState(state => {
      const text = TGFConverter.fromCytoscape(state.elements);

      return {
        text,
      }
    })
  };

  graphElementsChanged = () => {
    const elements = this.getCyElementsArray();
    let text = TGFConverter.fromCytoscape(elements);

    this.setState({
      text,
      elements,
    }, this.checkCentralitiesRefresh)
  };

  checkCentralitiesRefresh = () => {
    const {autoRefreshCentralities} = this.state;

    if (autoRefreshCentralities) {
      this.refreshCentralities();
    }
  };

  refreshCentralities = () => {
    this.setState(state => {
      const {centralities, isDirected} = state;

      for (let centrality of centralities) {
        centrality.setDirected(isDirected);
        centrality.resetValues();
      }

      return {
        centralities,
      }
    }, this.updateHighlightedCentralityData);
  };

  minMaxValuesForCentrality = (centrality) => {
    let min = Infinity, max = -Infinity;

    for (let value of Object.values(centrality.getValues())) {
      if (value < min) {
        min = value;
      }

      if (value > max) {
        max = value;
      }
    }

    return [min, max];
  }
}
