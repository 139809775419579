import exporter from "./exporter";
import FileSaver from "file-saver";

export default class PNGExporter extends exporter {
    export() {
        let b64key = 'base64,';
        let pngData = this.cy.png();
        let b64 = pngData.substring(pngData.indexOf(b64key) + b64key.length);
        let imgBlob = PNGExporter.base64ToBlob( b64, 'image/png' );
    
        FileSaver.saveAs( imgBlob, 'graph.png' );
    }
    
    static base64ToBlob(base64Data, contentType) {
        contentType = contentType || '';
        let sliceSize = 1024;
        let byteCharacters = atob(base64Data);
        let bytesLength = byteCharacters.length;
        let slicesCount = Math.ceil(bytesLength / sliceSize);
        let byteArrays = new Array(slicesCount);
        
        for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
            let begin = sliceIndex * sliceSize;
            let end = Math.min(begin + sliceSize, bytesLength);
            
            let bytes = new Array(end - begin);
            for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
                bytes[i] = byteCharacters[offset].charCodeAt(0);
            }
            byteArrays[sliceIndex] = new Uint8Array(bytes);
        }
        return new Blob(byteArrays, { type: contentType });
    }
}
