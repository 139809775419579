const GRAPH_TEST_B = {
  description: "Test graph B",
  text: `
1
2
3
4
5
#
1 2
1 3
1 4
2 3
2 5
3 4
4 1
5 1  
`
};

export default GRAPH_TEST_B;