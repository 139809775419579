import {FormGroup, NonIdealState, NumericInput} from "@blueprintjs/core";
import React from "react";

export default function Parameters({parameters, setParameters, parameterTypes}) {
  function setParameter(name, value) {
    setParameters({
      ...parameters,
      [name]: value,
    });
  }

  const setParameterAction = (name) => (value) => {
    setParameter(name, value);
  };

  let parameterComponents = [];

  if (parameterTypes.length > 0) {
    for (let parameterType of parameterTypes) {
      const {id, type, defaultValue} = parameterType;
      let parameterComponent;

      if (parameters[id] === undefined) {
        setParameter(id, defaultValue);
      }

      switch (type) {
        case 'integer':
          parameterComponent = (<IntegerParameter key={id}
            value={parameters[id]} setValue={setParameterAction(id)}
            info={parameterType}
          />);
          break;
        case 'float':
          parameterComponent = (<FloatParameter key={id}
            value={parameters[id]} setValue={setParameterAction(id)}
            info={parameterType}
          />);
          break;
        default:
          throw Error(`Unknown parameter type: ${type}`)
      }

      parameterComponents.push(parameterComponent)
    }
  } else {
    parameterComponents.push(<NonIdealState icon="tick" title="No parameters needed"/>)
  }

  return parameterComponents;
}

function IntegerParameter({value, setValue, info}) {
  return <FormGroup
    label={info.label}
    labelFor={info.id}
    helperText={info.description}
  >
    <NumericInput id={info.id}
      min={info.min} max={info.max}
      value={value} onValueChange={setValue} />
  </FormGroup>
}

function FloatParameter({value, setValue, info}) {
  return <FormGroup
    label={info.label}
    labelFor={info.id}
    helperText={info.description}
  >
    <NumericInput id={info.id}
      min={info.min} max={info.max} minorStepSize={0.05} stepSize={0.1} majorStepSize={0.5}
      value={value} onValueChange={setValue}
    />
  </FormGroup>
}