import {Centrality} from "../centrality";

export default class Betweenness extends Centrality {

  constructor(cy, parameters) {
    super(cy, parameters);
    this.calculateBetweennessForNode = cy.elements().betweennessCentrality({
      directed: this.isDirected,
      weight: this.getEdgeWeight
    }).betweenness
  }

  getName() {
    return "Betweenness";
  }

  calculateCentralityForNode(node) {
    return this.calculateBetweennessForNode(node);
  }

  updateCentralitiesValues() {
    this.calculateBetweennessForNode = this.cy.elements().betweennessCentrality({
      directed: this.isDirected,
      weight: this.getEdgeWeight
    }).betweenness;

    super.updateCentralitiesValues();
  }
}