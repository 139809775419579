import React from 'react';
import {Alignment, Button, ButtonGroup, InputGroup, Navbar, NumericInput, Popover, Position} from "@blueprintjs/core";

import ViewOptionsDropdown from "./ViewOptionsDropdown";

import "./GraphToolbar.scss";

export default function GraphToolbar({addNode, removeElement, redrawGraph, toggleHelp, displayOptions, toggleDisplayOptions, selectedElement, updateSelected}) {
  let label = "", weight = undefined;

  if (selectedElement) {
    label = selectedElement.data("label") || "";
    weight = selectedElement.data("weight") || undefined;
  }

  function setWeight(weight) {
    updateSelected({
      label,
      weight,
    })
  }

  function setLabel(event) {
    const label = event.target.value;

    updateSelected({
      label,
      weight,
    })
  }

  return (
    <Navbar className="GraphToolbar">
      <Navbar.Group align={Alignment.LEFT}>
        <ButtonGroup>
          <Button minimal onClick={addNode} icon="add" />
          <Button minimal onClick={removeElement} icon="remove" disabled={!selectedElement} />
          <Button minimal onClick={redrawGraph} icon="layout" />
        </ButtonGroup>
        <Navbar.Divider />
        <InputGroup
          leftIcon="tag"

          value={label}
          onChange={setLabel}

          type="text"
          placeholder="Label"

          disabled={!selectedElement}
        />
        <NumericInput
          leftIcon="numerical"

          value={weight}
          onValueChange={setWeight}

          placeholder="Weight"

          disabled={!selectedElement}
        />
        <Navbar.Divider />
        <Popover content={<ViewOptionsDropdown displayOptions={displayOptions} toggleDisplayOption={toggleDisplayOptions} />} position={Position.BOTTOM}>
          <Button minimal icon="eye-open" text="View" rightIcon="caret-down"/>
        </Popover>
      </Navbar.Group>
      <Navbar.Group align={Alignment.RIGHT}>
        <Button minimal onClick={toggleHelp} icon="help" />
      </Navbar.Group>
    </Navbar>
  );
}

// {/*<div id="graph-toolbar">*/}
// {/*    { this.state.selectedElement &&*/}
// {/*    <div id="edit-element">*/}
// {/*        <Input type="text" name="label" id="label" placeholder="Label"*/}
// {/*            value={this.state.formData['label']} onChange={this.handleFormChange}/>*/}
// {/*        <Input type="number" name="weight" id="weight" placeholder="Weight"*/}
// {/*            value={this.state.formData['weight']} onChange={this.handleFormChange}*/}
// {/*            className="ml-1"/>*/}
// {/*        */}
// {/*        <Button onClick={this.editElement} color={elementSelected ? 'primary' : 'secondary'}*/}
// {/*            className="ml-1" disabled={!elementSelected}>Zapisz</Button>*/}
// {/*    </div>*/}
// {/*    }*/}
// {/*</div>*/}