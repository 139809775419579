import React from "react";

import {Centrality} from "../centrality";
import {FormGroup, NumericInput} from "@blueprintjs/core";

export default class KStep extends Centrality{
  constructor(cy, parameters) {
    super(cy, parameters);
    this.k = parameters["k"];
  }

  getName() {
    return `K-Step (k=${this.k})`;
  }

  calculateCentralityForNode(node) {
      let value = 0;

      this.cy.elements().bfs({
        roots: node,
        visit: (currentNode, edge, previousNode, i, depth) => {
          if (depth <= this.k) {
            value++;
          } else {
            return false;
          }
        },
        directed: this.isDirected,
      });

      // BFS includes starting node, so we decrease value by 1
      return value - 1;
  }

  static getParametersComponent(parameters, setParameters) {
    return <KStepParameters parameters={parameters} setParameters={setParameters}/>
  }
}

function KStepParameters({parameters, setParameters}) {
  function setK(value) {
    setParameters({
      ...parameters,
      k: value,
    })
  }

  if (parameters["k"] === undefined) {
    setK(1)
  }

  return <>
    <FormGroup
      label="K ="
      labelFor="k"
      helperText="Maximum links between node and other nodes"
      inline
    >
      <NumericInput id="k" min={1} max={100} value={parameters["k"] || 1} onValueChange={setK} />
    </FormGroup>
  </>
}