import BarabasiAlbertGenerator from "./generator/BarabasiAlbertGenerator";
import ErdosRenyiGenerator from "./generator/ErdosRenyiGenerator";
import WattsStrogatzGenerator from "./generator/WattsStrogatzGenerator";
import Generator from "./Generator";

export default class GeneratorFactory {
  static getGeneratorObject(generator, parameters) {
    switch(generator) {
      case (GENERATOR_TYPES.BARABASI_ALBERT):
        return new BarabasiAlbertGenerator(parameters);
      case (GENERATOR_TYPES.ERDOS_RENYI):
        return new ErdosRenyiGenerator(parameters);
      case (GENERATOR_TYPES.WATTS_STROGATZ):
        return new WattsStrogatzGenerator(parameters);
      default:
        throw Error("Unknown graph generator");
    }
  }

  static getGeneratorParametersComponent(centrality, parameters, setParameters) {
    switch(centrality) {
      case (GENERATOR_TYPES.BARABASI_ALBERT):
        return BarabasiAlbertGenerator.getParametersComponent(parameters, setParameters);
      case (GENERATOR_TYPES.ERDOS_RENYI):
        return ErdosRenyiGenerator.getParametersComponent(parameters, setParameters);
      case (GENERATOR_TYPES.WATTS_STROGATZ):
        return WattsStrogatzGenerator.getParametersComponent(parameters, setParameters);
      default:
        return Generator.getParametersComponent(parameters, setParameters);
    }
  }
}

export const GENERATOR_TYPES = {
  BARABASI_ALBERT: "Barabasi-Albert",
  ERDOS_RENYI: "Erdos-Renyi",
  WATTS_STROGATZ: "Watts-Strogatz",
};