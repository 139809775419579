import exporter from "./exporter";
import TGFConverter from "../converters/TGFConverter";
import FileSaver from "file-saver";

export default class TGFExporter extends exporter {
    export() {
        let data = TGFConverter.fromCytoscape(this.elementsForExport);
        let blob = new Blob([data], {type: "text/plain;charset=utf-8"});
        FileSaver.saveAs(blob, 'graph.tgf');
    }
}