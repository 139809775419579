import Betweenness from "./centrality/betweenness";
import KStep from "./centrality/kstep";
import {Centrality} from "./centrality";
import Closeness from "./centrality/closeness";
import Degree from "./centrality/degree";
import Decay from "./centrality/decay";
import Harmonic from "./centrality/harmonic";

export default class CentralityFactory {
  static getCentralityObject(centrality, cy, parameters) {
    switch(centrality) {
      case (CENTRALITY_TYPES.BETWEENNESS):
        return new Betweenness(cy, parameters);
      case (CENTRALITY_TYPES.CLOSENESS):
        return new Closeness(cy, parameters);
      case (CENTRALITY_TYPES.KSTEP):
        return new KStep(cy, parameters);
      case (CENTRALITY_TYPES.DEGREE):
        return new Degree(cy, parameters);
      case (CENTRALITY_TYPES.DECAY):
        return new Decay(cy, parameters);
      case (CENTRALITY_TYPES.HARMONIC):
        return new Harmonic(cy, parameters);
      default:
        throw Error("Unknown centrality");
    }
  }

  static getCentralityParametersComponent(centrality, parameters, setParameters) {
    switch(centrality) {
      case (CENTRALITY_TYPES.KSTEP):
        return KStep.getParametersComponent(parameters, setParameters);
      case (CENTRALITY_TYPES.DEGREE):
        return Degree.getParametersComponent(parameters, setParameters);
      case (CENTRALITY_TYPES.DECAY):
        return Decay.getParametersComponent(parameters, setParameters);
      default:
        return Centrality.getParametersComponent(parameters, setParameters);
    }
  }
}

export const CENTRALITY_TYPES = {
  BETWEENNESS: "Betweenness",
  CLOSENESS: "Closeness",
  DEGREE: "Degree",
  KSTEP: "K-Step",
  DECAY: "Decay",
  HARMONIC: "Harmonic",
};