const GRAPH_TEST_A = {
  description: "Test graph A",
  text: `
1
2
3
4
5
6
#
1 2
1 3
2 3
3 2
3 5
4 5
5 2
6 1
`,
};

export default GRAPH_TEST_A;