import {
  Alignment,
  Button,
  ButtonGroup,
  Navbar,
  Switch
} from "@blueprintjs/core";
import React from "react";

export default function GraphTableToolbar({addCentrality, exportCentralities, autoRefresh, toggleAutoRefresh, manualRefresh}) {
  return (
    <Navbar className="GraphToolbar">
      <Navbar.Group align={Alignment.LEFT}>
        <ButtonGroup>
          <Button minimal onClick={addCentrality} icon="insert" text="Insert centrality" />
          <Button minimal onClick={exportCentralities} icon="export" text="Export to CSV" />
        </ButtonGroup>
      </Navbar.Group>
      <Navbar.Group align={Alignment.RIGHT}>
        <Switch checked={autoRefresh} innerLabel="manual reload" innerLabelChecked="auto reload"
          onChange={toggleAutoRefresh}/>
        <Button minimal onClick={manualRefresh} icon="refresh" text="Reload data" disabled={autoRefresh} />
      </Navbar.Group>
    </Navbar>
  );
}
