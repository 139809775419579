import GRAPH_TEST_A from "./data/testA";
import GRAPH_TEST_B from "./data/testB";
import TGFConverter from "../converters/TGFConverter";

export default class PredefinedFactory {
  static getPredefinedDescription(predefinedType) {
    switch(predefinedType) {
      case PREDEFINED_GRAPHS.TEST_A:
        return GRAPH_TEST_A.description;
      case PREDEFINED_GRAPHS.TEST_B:
        return GRAPH_TEST_B.description;
      default:
        throw Error("Unknown predefined graph");
    }
  }

  static getPredefinedElements(predefinedType) {
    switch(predefinedType) {
      case PREDEFINED_GRAPHS.TEST_A:
        return TGFConverter.toCytoscape(GRAPH_TEST_A.text);
      case PREDEFINED_GRAPHS.TEST_B:
        return TGFConverter.toCytoscape(GRAPH_TEST_B.text);
      default:
        throw Error("Unknown predefined graph");
    }
  }
}

export const PREDEFINED_GRAPHS = {
  TEST_A: "Test A",
  TEST_B: "Test B",
};