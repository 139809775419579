import _ from 'lodash';
import Generator from "../Generator";

const PARAMETER_TYPES = [
    {
        id: "initialNodesNumber",
        type: "integer",
        defaultValue: 5,

        label: "Initial nodes number",
        description: "Number of nodes in clique, with which we start generation",
        min: 1,
        max: 100,
    },
    {
        id: "nodesNumber",
        type: "integer",
        defaultValue: 10,

        label: "All nodes number",
        description: "Number of all nodes in generated graph. Must be lower than initial nodes number",
        min: 1,
        max: 100,
    },
];

export default class BarabasiAlbertGenerator extends Generator {
    static PARAMETER_TYPES = PARAMETER_TYPES;

    constructor(parameters) {
        super(parameters);
        this.nodesNumber = parameters['nodesNumber'];
        this.initialNodesNumber = parameters['initialNodesNumber'];
    }

    generateData() {
        if (this.initialNodesNumber > this.nodesNumber) {
            throw Error("Nodes number must be higher than edges number");
        }

        let data = {
            nodes: [],
            edges: [],
        };
        let repeatedNodes = [];
        let targets = [];

        // Adding initial nodes
        for (let nodeId = 1; nodeId <= this.initialNodesNumber; nodeId++) {
            data.nodes.push({
                data: {
                    id: nodeId,
                    label: nodeId,
                    weight: null,
                }
            });
            targets.push(nodeId);
        }

        // Creating clique for initial nodes
        for (let sourceId = 1; sourceId < this.initialNodesNumber; sourceId++) {
            for (let targetId = sourceId + 1; targetId <= this.initialNodesNumber; targetId++) {
                data.edges.push({
                    data: {
                        id: `${sourceId}-${targetId}`,
                        source: sourceId,
                        target: targetId,
                        weight: _.random(1, 10),
                        label: null,
                    }
                })
            }
        }

        for (let sourceId = this.initialNodesNumber + 1; sourceId <= this.nodesNumber; sourceId++) {
            data.nodes.push({
                data: {
                    id: sourceId,
                    label: sourceId,
                    weight: null,
                }
            });

            for (let targetId of targets) {
                data.edges.push({
                    data: {
                        id: sourceId + '-' + targetId,
                        source: sourceId,
                        target: targetId,
                        weight: _.random(1, 10),
                        label: null,
                    }
                });
            }

            repeatedNodes = repeatedNodes.concat(targets);
            repeatedNodes = repeatedNodes.concat(Array(this.initialNodesNumber).fill(sourceId));
            repeatedNodes = _.shuffle(repeatedNodes);

            targets = [];
            let currentId = 0;
            while (targets.length < this.initialNodesNumber) {
                let value = repeatedNodes[currentId];
                if (targets.indexOf(value) < 0) {
                    // new connection
                    targets.push(value);
                }
                currentId++;
            }
        }

        return data;
    }

    // static getParametersComponent(parameters, setParameters) {
    //     return <Parameters parameters={parameters} setParameters={setParameters} parameterTypes={PARAMETER_TYPES} />
    // }
}