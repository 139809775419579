import React from 'react';
import {Button, Card, Overlay} from "@blueprintjs/core";

import './ListWithParameters.scss';

export default function HelpPopover({isOpen, onClose}) {
  return <Overlay className="GenerationPopover ListWithParameters" isOpen={isOpen} onClose={onClose}>
    <Card className="bp3-dark">
      <h1>Graph editor</h1>
      <ul>
        <li>In left top corner you have buttons to add new node, remove currently selected element or reorganize graph.</li>
        <li>After clicking on a node or an edge fields for label and weight appear.</li>
        <li>You can double click anywhere to add node there.</li>
        <li>After you hover over than node, triangle appears above it. Drag it to node to create new edge</li>
      </ul>
      <Button text="Close" large onClick={onClose} />
    </Card>
  </Overlay>
}