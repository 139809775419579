export default class exporter {
    constructor({cy, elementsForExport, isDirected, displayOptions}) {
        this.cy = cy;
        this.elementsForExport = elementsForExport;
        this.isDirected = isDirected;
        this.displayOptions = displayOptions;
    }
    
    export() {
    
    }
    
    getComponent() {
        return '';
    }
}