import PNGExporter from "./png";
import TGFExporter from "./tgf";
import TikzExporter from "./tikz";

export default class exportFactory {
    static getExporter(type, options) {
        switch(type) {
            case EXPORT_TYPES.PNG:
                return new PNGExporter(options);
            case EXPORT_TYPES.TGF:
                return new TGFExporter(options);
            case EXPORT_TYPES.TIKZ:
                return new TikzExporter(options);
            default:
                throw Error("Incorrect export type");
        }
    }
}

export const EXPORT_TYPES = {
    PNG: 'Save as PNG',
    TGF: 'Save as TGF',
    TIKZ: 'Show Tikz code',
};
