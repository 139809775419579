import _ from 'lodash';
import Generator from "../Generator";

const PARAMETER_TYPES = [
    {
        id: "nodesNumber",
        type: "integer",
        defaultValue: 10,

        label: "Number of nodes",
        description: "Number of nodes in graph we are generating",
        min: 1,
        max: 100,
    },
    {
        id: "edgesPerNodesSideNumber",
        type: "integer",
        defaultValue: 2,

        label: "Edges per node side",
        description: "How many neighbours on each side is connected to given node",
        min: 1,
        max: 10,
    },
    {
        id: "edgeRewiringProbability",
        type: "float",
        defaultValue: 0.1,

        label: "Edge rewiring probability",
        description: "Probability of replacing existing edge with another one chosen at random",
        min: 0,
        max: 1,
    },
];

export default class WattsStrogatzGenerator extends Generator {
    static PARAMETER_TYPES = PARAMETER_TYPES;

    constructor(parameters) {
        super(parameters);
        this.nodesNumber = parameters["nodesNumber"];
        this.edgesPerNodesSideNumber = parameters["edgesPerNodesSideNumber"];
        this.edgeRewiringProbability = parameters["edgeRewiringProbability"];
    }

    generateData() {
        if (this.edgesPerNodesSideNumber * 2 >= this.nodesNumber) {
            throw Error("Doubled edges per side number must be lower than nodes number");
        }

        let edgeId = 0;

        let data = {
            nodes: [],
            edges: [],
        };

        // Adding initial edges
        for (let nodeId = 1; nodeId <= this.nodesNumber; nodeId++) {
            data.nodes.push({
                data: {
                    id: nodeId,
                    label: nodeId,
                    weight: null,
                }
            });
        }

        let connections = [];

        for (let sourceId = 1; sourceId <= this.nodesNumber; sourceId++) {
            for (let targetId of this.neighboursOfNode(sourceId)) {
                connections.push([sourceId, targetId]);
            }
        }

        for (let sourceId = 1; sourceId <= this.nodesNumber; sourceId++) {
            for (let targetId of this.neighboursOfNode(sourceId)) {
                if (Math.random() < this.edgeRewiringProbability) {
                    _.remove(connections, ([source, target]) => ((source === sourceId) && (target === targetId)));

                    let newTargetId = _.random(1, this.nodesNumber);
                    const checkSource = ([source, target]) => ((source === sourceId) && (target === newTargetId));
                    const checkTarget = ([source, target]) => ((source === newTargetId) && (target === sourceId));

                    while (newTargetId === sourceId ||
                            _.find(connections, checkSource) !== undefined ||
                            _.find(connections, checkTarget) !== undefined) {
                        newTargetId = _.random(1, this.nodesNumber);
                    }
                    connections.push([sourceId, newTargetId]);
                }
            }
        }

        for (let [sourceId, targetId] of connections) {
            data.edges.push({
                data: {
                    id: `${sourceId}->${targetId}-${edgeId}`,
                    source: sourceId,
                    target: targetId,
                    weight: _.random(1, 10),
                    label: null,
                }
            });
            edgeId++;
        }

        return data;
    }

    neighboursOfNode(nodeId) {
        let neighbours = [];

        for (let i = this.edgesPerNodesSideNumber; i >= 1; i--) {
            // To get only positive numbers, we add nodeNumber to nodeId
            neighbours.push((this.nodesNumber + nodeId - i) % this.nodesNumber || this.nodesNumber);
        }

        // We split this so we get nodes in order
        for (let i = 1; i <= this.edgesPerNodesSideNumber; i++) {
            // We are starting numeration from 1, so if result is equal to 0, we change it to last available node
            neighbours.push((nodeId + i) % this.nodesNumber || this.nodesNumber);

        }

        return neighbours;
    }
}