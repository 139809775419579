import React, {Component} from "react";

import TikzConverter from "../converters/TikzConverter";
import exporter from "./exporter";
import {Card, Classes, Overlay, TextArea} from "@blueprintjs/core";

export default class TikzExporter extends exporter {
    constructor(props) {
        super(props);
        this.text = '';
    }
    
    export() {
        this.text = TikzConverter.fromCytoscape(this.elementsForExport, this.isDirected, this.displayOptions);
    }
    
    getComponent() {
        return <TikzOverlay key={Date.now()} text={this.text}/>
    }
}

class TikzOverlay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: true,
        };
    }
    
    hide = () => {
        this.setState({
            visible: false,
        })
    };
    
    render() {
        const {visible} = this.state;
        const {text} = this.props;
        
        const textAreaStyle = {
            width: "100%",
            height: "100%",
        };
        
        
        return (
            <Overlay isOpen={visible} onClose={this.hide} className={Classes.DARK}>
                <Card>
                    <TextArea value={text} style={textAreaStyle} />
                </Card>
            </Overlay>
        )
    }
}