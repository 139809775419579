import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FileSaver from 'file-saver';
// import ErdosRenyiGenerator from "../generators/ErdosRenyiGenerator";
// import BarabasiAlbertGenerator from "../generators/BarabasiAlbertGenerator";
// import WattsStrogatzGenerator from "../generators/WattsStrogatzGenerator";
import PajekConverter from "../converters/PajekConverter";
import _ from "lodash";

import TGFConverter from "../converters/TGFConverter";
import Cytoscape from "./Cytoscape";
import GraphToolbar from "./subcomponents/GraphVisualization/GraphToolbar";

const GRAPHS = {
  a:
    `1 A
2 B
3 C
4 D
#
1 2 2
2 3 4
3 1 5
4 2 6`,
  b:
    `1 A
2 B
3 C
4 D
#
2 3 4
4 1 3
2 4 6
1 2 7`,
};

export default class GraphVisualization extends Component {
  static propTypes = {
    cy: PropTypes.object,

    onGraphChange: PropTypes.func,
    changeLayout: PropTypes.bool,
    isDirected: PropTypes.bool,

    exportComponent: PropTypes.node,

    addElement: PropTypes.func,
    toggleHelp: PropTypes.func,
  };

  static defaultProps = {
    onGraphChange: () => {},
    changeLayout: true,
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedElement: false,
      formData: this.getCleanFormData(),

      generatorData: {
        type: 'erdos-renyi',
        nodesNumber: 10,
        parameter1: 0.5,
        parameter2: 0.5,
      },

      edgesDirected: props.isDirected,

      fileToImportContents: false,
      fileToImportName: false,
      predefinedGraph: '',
      activeTab: 'view-options',

      showCentralitiesTable: false,
      showHelp: false,
      showTikz: false,

      tikzText: '',

      exportComponent: '',
    };
  }

  render() {
    const {selectedElement} = this.state;
    const {displayOptions, toggleDisplayOptions, cy, isDirected, elements, exportComponent, onGraphChange, graphId, toggleHelp} = this.props;

    return (
      <div className="GraphVisualization">
        {exportComponent}
        <GraphToolbar
          addNode={this.addNode} removeElement={this.removeElement}
          selectedElement={selectedElement} updateSelected={this.updateSelectedElement}
          redrawGraph={this.redrawGraph} toggleHelp={toggleHelp}
          displayOptions={displayOptions} toggleDisplayOptions={toggleDisplayOptions}
        />
        <Cytoscape
          cy={cy} updateCy={this.props.updateCy}
          displayOptions={displayOptions} isDirected={isDirected}
          elements={elements} graphId={graphId}
          onNodeClick={this.nodeSelected} onEdgeClick={this.edgeSelected} onBackgroundClick={this.backgroundSelected}
          onDoubleClick={this.addNodeEvent} onGraphChange={onGraphChange}
        />
      </div>
    )
  }

  updateSelectedElement = ({label, weight}) => {
    this.state.selectedElement.data("label", label);
    this.state.selectedElement.data("weight", weight);

    this.props.onGraphChange();
  };

  changePredefinedGraph = (event) => {
    this.setState({
      predefinedGraph: event.target.value
    });
  };

  loadPredefinedGraph = () => {
    if (this.state.predefinedGraph) {
      let elements = TGFConverter.toCytoscape(GRAPHS[this.state.predefinedGraph]);
      this.props.cy.json({elements});
      this.graphChanged();
    }
  };

  importFileChanged = (event) => {
    let file = event.target.files[0];
    if (!file) {
      return;
    }
    let reader = new FileReader();
    let contents = false;
    reader.onload = (e) => {
      contents = e.target.result;
      this.setState({
        fileToImportContents: contents,
        fileToImportName: file.name,
      })
    };
    reader.readAsText(file);
  };

  importFromGML = () => {
    if (this.state.fileToImportContents) {
      this.props.cy.json({
        elements: [],
      });
      this.props.cy.graphml(this.state.fileToImportContents);
      this.graphChanged();
    }
  };

  importFromPajek = () => {
    if (this.state.fileToImportContents) {
      this.props.cy.json({
        elements: PajekConverter.toCytoscape(this.state.fileToImportContents),
      });
      this.graphChanged();
    }
  };

  importFromTGF = () => {
    if (this.state.fileToImportContents) {
      this.props.cy.json({
        elements: TGFConverter.toCytoscape(this.state.fileToImportContents),
      });
      this.graphChanged();
    }
  };

  handleFormChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState((prevState) => {
      prevState.formData[name] = value;

      return {
        formData: prevState.formData,
      }
    });
  };

  handleCentralityChanged = (event) => {
    const centralityId = event.target.id;
    this.setState((state) => {
      if (state.centralities.selected.includes(centralityId)) {
        _.pull(state.centralities.selected, centralityId);
      } else {
        state.centralities.selected.push(centralityId);
      }

      return {
        centralities: state.centralities
      }
    })
  };

  getCleanFormData = () => {
    return {
      label: '',
      weight: '',
      source: '',
      target: '',
    }
  };

  setFormData = (element) => {
    let data = element.json().data;

    this.setState({
      formData: {
        label: data.label ? data.label : '',
        weight: data.weight ? data.weight : '',
        source: data.source ? data.source : '',
        target: data.target ? data.target : '',
      }
    })
  };

  handleCentralityParameterChanged = (id) => (event) => {
    const value = event.target.value;

    this.setState((state) => {
      state.centralities.parameters[id] = value;

      return {
        centralities: state.centralities,
      }
    })
  };


  nodeSelected = (selectedNode) => {
    this.graphElementSelected(selectedNode);
  };

  edgeSelected = (selectedEdge) => {
    this.graphElementSelected(selectedEdge)
  };

  backgroundSelected = () => {
    this.graphElementSelected(false);
  };

  graphElementSelected = (element) => {
    if (this.state.selectedElement)
      this.state.selectedElement.removeClass('selected');

    if (element) {
      element.addClass('selected');
      this.setFormData(element);
    }

    this.setState({
      selectedElement: element,
    });
  };

  getNodeDataWithNewId = () => {
    let formData = {};
    formData['id'] = this.getMaxId() + 1;

    return formData;
  };

  getElementData = (edge = true) => {
    let formData = this.state.formData;

    let elementData = {
      label: formData.label !== '' ? formData.label : null,
      weight: formData.weight !== '' ? formData.weight : null,
    };

    if (edge) {
      elementData.source = formData.source !== '' ? formData.source : null;
      elementData.target = formData.target !== '' ? formData.target : null;
    }

    return elementData;
  };

  addNodeEvent = (event) => {
    this.addNode(event.position, false);
  };

  addNode = (position = undefined, changeLayout = true) => {
    const {addElement} = this.props;

    let formData = this.getNodeDataWithNewId();
    let nodeInfo = {
      group: 'nodes',
      data: formData,
    };

    if (position) {
      nodeInfo.position = position;
    } else {
      let newPosition = this.getTopLeftPosition();
      nodeInfo.position = {
        x: newPosition[0] - 10,
        y: newPosition[1] - 10
      };
    }

    addElement(nodeInfo);
  };

  removeElement = () => {
    this.props.cy.remove(this.state.selectedElement);
    this.graphChanged(false);

    this.setState({
      formData: this.getCleanFormData(),
      selectedElement: false,
    })
  };

  graphChanged = (changeLayout = true) => {
    this.props.onGraphChange(this.getElementsForExport(), changeLayout);
  };

  clearElement = () => {
    this.setState({
      formData: this.getCleanFormData()
    })
  };

  editElement = () => {
    let element = this.state.selectedElement;
    if (element) {
      element.data(this.getElementData());
    }
    this.graphChanged(false);
  };

  getElementsForExport = () => {
    let elements = [];

    for (let node of this.props.cy.json().elements.nodes) {
      if (!node.classes.includes('eh-ghost') && !node.classes.includes('eh-handle')) {
        elements.push(node)
      }
    }

    for (let edge of this.props.cy.json().elements.edges) {
      if (!edge.classes.includes('eh-ghost')) {
        elements.push(edge)
      }

    }

    return elements;
  };

  toggleHelp = () => {
    this.setState((state) => {
      return {
        showHelp: !state.showHelp,
      };
    });
  };

  refreshCytoscapeConfig = () => {
    let elements = this.props.elements;
    if (elements.nodes) {
      elements.nodes.forEach((node) => {
        delete node.position;
      })
    }
    this.cytoscapeConfig.elements = elements;
    this.props.cy.json(this.cytoscapeConfig);
  };

  redrawGraph = () => {
    this.props.cy.layout({
      name: 'cola',
    }).run();
  };

  getMaxId = () => {
    let maxId = 0;
    this.props.cy.nodes().forEach((node) => {
      if (node.id() > maxId) {
        maxId = parseInt(node.id());
      }
    });

    return maxId;
  };

  getTopLeftPosition = () => {
    let minX = Infinity, minY = Infinity;
    this.props.cy.nodes().forEach((node) => {
      const [x, y] = [node.position().x, node.position().y];
      minX = Math.min(x, minX);
      minY = Math.min(y, minY);
    });

    return [minX, minY];
  };

  getEdgeWeight = (edge) => {
    return edge.data('weight');
  };


  exportCentralitiesToCSV = () => {
    let csvString = "id,label";
    let centralitiesData = this.state.centralities.data;
    let calculatedCentralities = Object.keys(centralitiesData);
    for (let centralityId of calculatedCentralities) {
      csvString += `,${centralityId}`;
    }
    csvString += "\n";
    this.props.cy.nodes().forEach((node) => {
      let id = node.data('id');
      let label = node.data('label');
      csvString += `${id},"${(label ? label : '')}"`;
      for (let centralityId of calculatedCentralities) {
        csvString += `,${centralitiesData[centralityId][id]}`
      }
      csvString += "\n";
    });

    let blob = new Blob([csvString], {type: "text/plain;charset=utf-8"});
    FileSaver.saveAs(blob, 'centralities.csv');
  };
}