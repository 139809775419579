import {Colors} from "@blueprintjs/core";

let mainColor = Colors.COBALT3;
const centralityColors = ["#B3CFFF", "#A5C1F5", "#98B3EA", "#8AA5E0", "#7D97D5", "#6F8ACB", "#617DC1", "#5370B7", "#4463AD", "#3357A3", "#1F4B99"];
let selectedColor = Colors.RED3;
let connectorColor = Colors.COBALT1;

function nodeContent(element) {
    const nodeData = element.data();
    const displayOptions = element.cy().scratch("_displayOptions");
    let label = '';
    let showId = displayOptions.node_id;
    let showLabel = displayOptions.node_label && (nodeData.label !== null) && (nodeData.label !== undefined);
    let showWeight = displayOptions.node_weight && (nodeData.weight !== null) && (nodeData.weight !== undefined);
    let showCentrality = displayOptions.centrality && nodeData['centrality'] !== undefined && nodeData['centrality'] !== null;

    if (showId) {
        label = `${nodeData.id}`;
    }

    let description = '';
    if (showLabel) {
        description += `${nodeData.label}`;
    }

    if (showWeight) {
        if (showLabel) {
            if (description)
                description += ' ';
            description += `(${nodeData.weight})`;
        } else {
            description = `${nodeData.weight}`;
        }
    }

    if (description) {
        if (showId)
            label += `\n${description}`;
        else
            label = `${description}`;
    }

    if (showCentrality) {
        if (!isNaN(nodeData['centrality']))
            if (label)
                label += `\n C: ${nodeData['centrality'].toFixed(2)}`;
            else
                label += `${nodeData['centrality'].toFixed(2)}`;
    }

    return label;
}

function nodeFontSize(element) {
    let nodeData = element.data();
    const displayOptions = element.cy().scratch("_displayOptions");

    let showId = displayOptions.node_id;
    let showLabel = displayOptions.node_label && (nodeData.label !== null);
    let showWeight = displayOptions.node_weight && (nodeData.weight !== null);
    let showCentrality = displayOptions.centrality && nodeData['centrality'] !== undefined && nodeData['centrality'] !== null;
    let textSize = 14;

    if (showId) {
        textSize -= 2;
    }

    if (showLabel || showWeight) {
        textSize -= 2;
    }

    if (showCentrality) {
        textSize -=2;
    }

    return textSize;
}

function nodeColor(element) {
    const centralityPercentage = element.data("centralityPercentage");
    let showCentrality = element.cy().scratch("_displayOptions").centrality && typeof centralityPercentage === "number";

    if (showCentrality) {
        return centralityColors[Math.round(centralityPercentage / 0.1)];
    } else {
        return mainColor;
    }
}

function edgeContent(element) {
    const edgeData = element.data();
    const displayOptions = element.cy().scratch("_displayOptions");
    let label = '';

    if (displayOptions.edge_label && edgeData.label) {
        label = edgeData.label
    }

    if (displayOptions.edge_weight && edgeData.weight) {
        label += ` (${edgeData.weight})`
    }

    return label;
}

function edgeTargetArrowShape(element) {
    const isDirected = element.cy().scratch("_isDirected");

    return isDirected ? 'triangle' : 'none';
}

let CYTOSCAPE_CONFIG = {
    boxSelectionEnabled: false,
    autounselectify: true,
    zoomingEnabled: true,
    elements: [],
    style: [
        {
            selector: 'node',
            style: {
                'text-valign': 'center',
                'text-halign': 'center',

                'text-opacity': 1,
                'color': '#ffffff',
                'background-color': nodeColor,

                'font-family': ['Lato', 'sans-serif'],

                'text-wrap': 'wrap',
                'text-outline-color': nodeColor,
                'text-outline-width': 1,

                'text-margin-x': 0,
                'text-margin-y': 0,

                'content': nodeContent,
                'font-size': nodeFontSize,
            }
        },
        {
            selector: 'edge',
            style: {
                'content': edgeContent,

                'text-rotation': 'autorotate',
                'font-size': 6,
                'width': 2,
                "curve-style": "bezier",
                'target-arrow-shape': edgeTargetArrowShape,
                'line-color': '#555555',
                'target-arrow-color': '#333',
                'text-outline-color': '#FFF',
                'text-outline-width': 1,
                // 'text-margin-y': '-6',
            }
        },
        {
            selector: 'edge.selected',
            style: {
                'overlay-color': selectedColor,
                'overlay-padding': 5,
                'overlay-opacity': 0.5,
            }
        },
        {
            selector: 'node.selected',
            style: {
                'overlay-color': selectedColor,
                'overlay-padding': 2,
                'overlay-opacity': 0.5,
            }
        },
        {
            selector: '.eh-handle',
            style: {
                'content': '',
                'width': '10%',
                'height': '10%',
                'shape': 'vee',
                'background-color': connectorColor,
                'border-width': 1,
                'border-color': "#FFFFFF",
            }
        },
        {
            selector: '.eh-preview',
            style: {
                "line-style": "dashed",
                "line-color": selectedColor,
                "target-arrow-color": selectedColor,
            }
        },
        {
            selector: '.eh-ghost-edge',
            style: {
                "line-color": selectedColor,
                "target-arrow-color": selectedColor,
            }
        }
    ],
    layout: {
        name: 'cola'
    }
};

export default CYTOSCAPE_CONFIG;