import React, {Component} from "react";
import {Cell, Column, ColumnHeaderCell, Table} from "@blueprintjs/table";
import propTypes from 'prop-types';
import {Alignment, Menu} from "@blueprintjs/core";

import "./GraphTable.scss";
import GraphTableToolbar from "./subcomponents/GraphTable/GraphTableToolbar";
import * as _ from "lodash";
import FileSaver from "file-saver";


export default class GraphTable extends Component {
    static propTypes = {
        centralities: propTypes.array,
        nodes: propTypes.array,

        autoRefresh: propTypes.bool,
        toggleAutoRefresh: propTypes.func,

        highlightedCentralityIndex: propTypes.number,
        setHighlightedCentralityIndex: propTypes.func,

        addCentrality: propTypes.func,
        removeCentralityByIndex: propTypes.func,
        manualRefresh: propTypes.func,
    };

    constructor(props) {
        super(props);

        this.state = {
            sortDirection: "asc",
            sortIndex: undefined,
            sortedNodes: props.nodes,
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        const {sortDirection, sortIndex} = this.state;
        const {autoRefresh, nodes, highlightedCentralityIndex} = this.props;

        if (nextState.sortDirection !== sortDirection
          || nextState.sortedIndex !== sortIndex
          || nextProps.autoFocus !== autoRefresh
          || nextProps.highlightedCentralityIndex !== highlightedCentralityIndex
        ) {
            return true
        }

        if (!_.isEqual(nextProps.nodes, nodes)) {
            return true
        }

        for (let centrality of nextProps.centralities) {
            if (!centrality.hasValues()) {
                return true;
            }
        }

        return false
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.nodes, this.props.nodes)) {
            this.sort();
        }
    }

    sort() {
        this.setState(state => {
            let sortedNodes = [...this.props.nodes];
            const {sortIndex, sortDirection} = state;

            if (sortIndex === undefined) {
                sortedNodes.sort();
            } else {
                sortedNodes.sort(this.sortByCentrality(sortIndex));
            }

            if (sortDirection === 'desc') {
                sortedNodes.reverse();
            }

            return {
                sortedNodes,
            }
        })
    }

    sortByCentrality = (centralityIndex) => {
        const centrality = this.props.centralities[centralityIndex];

        return (nodeA, nodeB) => {
            return centrality.getValue(nodeA.id()) - centrality.getValue(nodeB.id());
        }
    };

    setSortingOptionsAction = (centralityIndex, direction) => () => {
        this.setState({
            sortIndex: centralityIndex,
            sortDirection: direction,
        }, this.sort)
    };

    removeCentralityAction = (centralityIndex) => () => {
        const {removeCentralityByIndex} = this.props;
        removeCentralityByIndex(centralityIndex);
    };

    highlightCentralityAction = (centralityIndex) => () => {
        const {setHighlightedCentralityIndex} = this.props;
        setHighlightedCentralityIndex(centralityIndex);
    };

    render() {
        const {centralities} = this.props;
        const {sortedNodes} = this.state;

        const centralityColumns = centralities.map((centrality, index) => (
          <Column key={index} columnHeaderCellRenderer={this.CentralityHeader({
              centrality,
              index,
              sortAction: this.setSortingOptionsAction,
              removeAction: this.removeCentralityAction,
              highlightAction: this.highlightCentralityAction,
          })} cellRenderer={this.CentralityCell({centrality})} />
        ));

        return (
            <div className="GraphTable">
                <GraphTableToolbar addCentrality={this.props.addCentrality} exportCentralities={this.exportCentralities} autoRefresh={this.props.autoRefresh} toggleAutoRefresh={this.props.toggleAutoRefresh} manualRefresh={this.manualRefresh} />
                <Table numRows={sortedNodes.length} enableRowHeader={false}>
                    <Column columnHeaderCellRenderer={this.NodeHeader} cellRenderer={this.NodeCell} />
                    { centralityColumns }
                </Table>
            </div>
        )
    }

    manualRefresh = () => {
        this.props.manualRefresh();
        this.forceUpdate();
    };

    NodeHeader = () => {
        return <ColumnHeaderCell name="Label"
          menuRenderer={this.NodeHeaderMenu}
        />
    };

    NodeHeaderMenu = () => {
        return <Menu>
            <Menu.Item text="Sort (ascending)" icon="sort-asc" onClick={this.setSortingOptionsAction(undefined, 'asc')} />
            <Menu.Item text="Sort (descending)" icon="sort-desc" onClick={this.setSortingOptionsAction(undefined, 'desc')} />
        </Menu>
    };

    NodeCell = (rowIndex) => {
        const {sortedNodes} = this.state;

        return <Cell className={Alignment.CENTER}>
            {sortedNodes[rowIndex].data("label") ? sortedNodes[rowIndex].data("label") : sortedNodes[rowIndex].id()}
        </Cell>
    };

    CentralityHeader = ({centrality, index, sortAction, removeAction, highlightAction}) => () => {
        const {highlightedCentralityIndex} = this.props;

        return <ColumnHeaderCell
          name={`${centrality.getName()}`}
          style={highlightedCentralityIndex === index ? {fontWeight: 700} : {}}
          menuRenderer={this.CentralityHeaderMenu({
            centrality,
            sortAsc: sortAction(index, "asc"),
            sortDesc: sortAction(index, "desc"),
            highlight: highlightAction(index),
            remove: removeAction(index),
          })}
        />;
    };

    CentralityHeaderMenu = ({sortAsc, sortDesc, highlight, remove}) => () => {
        return <Menu>
            <Menu.Item text="Sort (ascending)" icon="sort-asc" onClick={sortAsc} />
            <Menu.Item text="Sort (descending)" icon="sort-desc" onClick={sortDesc} />
            <Menu.Divider />
            <Menu.Item text={"Show on visualization"} icon="annotation" onClick={highlight} />
            <Menu.Divider />
            <Menu.Item text="Remove" icon="delete" onClick={remove} />
        </Menu>
    };

    CentralityCell = ({centrality}) => (rowIndex) => {
        const {sortedNodes} = this.state;
        const nodeId = sortedNodes[rowIndex].id();
        const value = centrality.getValue(nodeId);

        console.log('cell updated');

        return <Cell className={Alignment.CENTER}>
            {value}
        </Cell>
    };

    exportCentralities = () => {
        const {centralities} = this.props;
        const {sortedNodes} = this.state;

        let csvString = "Label";
        for (let centrality of centralities) {
            csvString += `;${centrality.getName()}`
        }
        csvString += "\r";

        for (let node of sortedNodes) {
            const label = node.data("label");
            const id = node.id();
            csvString += label ? label : id;

            for (let centrality of centralities) {
                csvString += `;${centrality.getValue(id)}`
            }

            csvString += "\r";
        }

        let blob = new Blob([csvString], {type: "text/plain;charset=utf-8"});
        FileSaver.saveAs(blob, 'centralities.csv');
    };
}