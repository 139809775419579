import {Centrality} from "../centrality";

export default class Closeness extends Centrality {


  getName() {
    return "Closeness";
  }

  calculateCentralityForNode(node) {
    return this.cy.elements().closenessCentrality({
      directed: this.isDirected,
      weight: this.getEdgeWeight,
      root: node
    });
  }
}