import {Centrality} from "../centrality";
import {FormGroup, NumericInput} from "@blueprintjs/core";
import React from "react";

export default class Decay extends Centrality {
  constructor(cy, parameters) {
    super(cy, parameters);
    this.delta = parameters["delta"]
  }

  getName() {
    return `Decay (delta=${this.delta})`;
  }

  calculateCentralityForNode(node) {
    let value = 0;
    let lastDepth = 1;
    let currentSum = 0;

    this.cy.elements().bfs({
      roots: node,
      visit: (currentNode, edge, previousNode, i, depth) => {
        if (depth > 0) {
          if (depth !== lastDepth) {
            value += currentSum * Math.pow(this.delta, lastDepth - 1);
            lastDepth = depth;
            currentSum = 0;
          }

          currentSum++;
        }
      },
      directed: this.isDirected,
    });

    return value + currentSum * Math.pow(this.delta, lastDepth - 1);
  }

  static getParametersComponent(parameters, setParameters) {
    return <DecayParameters parameters={parameters} setParameters={setParameters}/>
  }
}

function DecayParameters({parameters, setParameters}) {
  function setDelta(value) {
    setParameters({
      ...parameters,
      delta: value,
    })
  }

  if (parameters["delta"] === undefined) {
    setDelta(0.5)
  }

  return <>
    <FormGroup
      label="Δ ="
      labelFor="delta"
      helperText="Probability of a successful move from one node to another via an edge (from 0 to 1)"
      inline
    >
      <NumericInput id="delta" min={0} max={1} minorStepSize={0.05} stepSize={0.1} majorStepSize={0.2} value={parameters["delta"] || 0.5} onValueChange={setDelta} />
    </FormGroup>
  </>
}