import React, {useState} from 'react';
import {Alignment, Button, Card, H1, NonIdealState, Overlay} from "@blueprintjs/core";
import {Select} from "@blueprintjs/select";

import GeneratorFactory, {GENERATOR_TYPES} from "../../generators/GeneratorFactory";
import {listElementPredicate, listElementRenderer} from "./ListWithParameters/helpers";
import './ListWithParameters.scss';

export default function GenerationPopover({isOpen, onClose, updateGraphData}) {
  const [selectedGenerator, setSelectedGenerator] = useState(null);
  const [parameters, setParameters] = useState({});

  const generatorSelected = (generator) => {
    setSelectedGenerator(generator);
  };

  function getGeneratorType() {
    if (selectedGenerator)
      return selectedGenerator[1];
    else
      return undefined;
  }

  const getParametersComponent = () => {
    const type = getGeneratorType();

    if (!type) {
      return <NonIdealState icon="cross" title="No generator selected" description="Choose graph generator from list above to set it's parameters and to generate graph." />
    } else {
      return GeneratorFactory.getGeneratorParametersComponent(type, parameters, setParameters);
    }
  };

  const generateData = () => {
    const type = getGeneratorType();

    if (type) {
      const generator = GeneratorFactory.getGeneratorObject(type, parameters);
      updateGraphData(generator.generateData());
      onClose();
    }
  };

  return <Overlay className="GenerationPopover ListWithParameters" isOpen={isOpen} onClose={onClose}>
    <Card className="bp3-dark">
      <H1>Generate data</H1>
      <Select
        className="select-element" popoverProps={{usePortal: false}}
        items={Object.entries(GENERATOR_TYPES)} onItemSelect={generatorSelected}
        itemRenderer={listElementRenderer(selectedGenerator)}
        itemPredicate={listElementPredicate}
      >
        <Button large text={selectedGenerator ? selectedGenerator[1] : "Choose generator type"}
          icon="graph" rightIcon="caret-down"
          alignText={Alignment.LEFT}
        />
      </Select>
      <div className="parameters">
        { getParametersComponent() }
      </div>
      <Button className="add-element-button" text="Generate graph" large disabled={!selectedGenerator} onClick={generateData} />
    </Card>
  </Overlay>
}